import "./App.css";
import { FaLinkedinIn, FaTwitter, FaTruck } from "react-icons/fa";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1><FaTruck/></h1>
        <h1>Hi! I'm Sohaib.</h1>
        <h2>I'm interested in and work for startups.</h2>
        <p id="main">
          I currently work at{" "}
          <a
            className="App-link"
            href="https://www.convoy.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Convoy
          </a>
          , where I help develop cutting-edge experimentation tools for data
          scientests. We optimize freight matching to reduce carbon emissions and supply chain
          inefficiencies.
        </p>
        <p>
          Previously{" "}
          <a
            className="App-link"
            href="https://www.amazon.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Amazon
          </a>{" "}
          and{" "}
          <a
            className="App-link"
            href="https://www.cs.washington.edu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            UW CSE
          </a>
          .
        </p>
        <div id="icons">
          <a
            className="App-link"
            href="https://twitter.com/whoissohaib"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            className="App-link"
            href="https://www.linkedin.com/in/sohaibmoinuddin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
